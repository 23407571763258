import Big from 'big.js';

const getValidNumber = (value: number) => (Number.isNaN(value) ? 0 : value);

export const safelyMultiplyNumbers = (
  value: number,
  ...multiplier: number[]
): number =>
  multiplier
    .reduce(
      (acc, num) => acc.times(Big(getValidNumber(num))),
      Big(getValidNumber(value)),
    )
    .toNumber();
